import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from '../common/common';
import { MenuMasterVM } from '../models/MenuMasterVM';
import { Observable } from 'rxjs';
import { ResponseModelList } from '../models/ResponseModelList';
import { MenuResponse } from '../models/menu/MenuResponse';
import { ApiUrls } from '../common/api-urls';
import { UrlParameter } from '../common/constant';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: MenuMasterVM = new MenuMasterVM();
  forUpdate: boolean;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  options = { headers: this.headers };

  constructor(private http: HttpClient) {}

  getList() {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<MenuResponse>>(
      ApiUrls.menu,
      options
    );
  }

  getMenuByRole(): Observable<ResponseModelList<MenuResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<MenuResponse>>(
      ApiUrls.menuByRoleId.replace(
        UrlParameter.roleId,
        Common.loginResponseModel.roleId.toString()
      ),
      options
    );
  }
}
