import { HttpHeaders } from '@angular/common/http';
import { LoginResponseV2 } from '../models/auth/LoginResponse';

import { FormGroup, ValidatorFn } from '@angular/forms';
import { BaseMenu } from '../models/menu/BaseMenu';
import { saveAs } from 'file-saver';


export class Common {







  static getEPOCHByDate(date:Date) {
        return Math.round(date.getTime() / 1000 );
      }
      
       


  static emailPattern = '^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+){1,}[\\s\\,]*)+$';
  static singleEmailPattern = '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  static passwordValidationPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{6,}$';
  static notAllowSpecialChars = '^[a-z A-Z - 0-9]+(-[a-z A-Z 0-9]+)?$';
  
  static token: string;
  static loginResponseModel: LoginResponseV2;
  static menulist: BaseMenu[];
  private static CountryStateCityAPIKey =
    'amIybklBSlNoWm90Slo1QnVCcUQzUE8ybGVTVnpTVjhNZHN6OUJqUQ==';
  filteredValues: any;
  
  // static partnerRights= false;
  static isNullOrEmpty(data): boolean {
    if (data) {
      return false;
    } else {
      return true;
    }
  }



  static isNumber(value): number {
    return isNaN(value) || value === '' || value == null ? 0 : parseFloat(value);
  }

  static getUTCDate(): string {
    return (new Date()).toUTCString();
  }

  static getCurrentEPOCH(): number {
    return Math.round(new Date().getTime() * 1000);
  }

  static getEPOCH(date: Date): number {
    return Math.round(date.getTime());
  }

  static getDateFromEPOCH(epoc: any): Date {
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(epoc);
    return date;
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static getHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true'
    });
    return headers;
  }
  static getHeaderEle(): HttpHeaders {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods' : 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'Connection': 'Keep-Alive',
    });
    return headers;
  }

  static getAuthorizeHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }

  static getAuthorizeHeaderForNode(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  static getAuthorizeMultiPartHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }

  static getCountryStateCityAPIHeader(): HttpHeaders {
    const headers = new HttpHeaders({
        'X-CSCAPI-KEY': `${Common.CountryStateCityAPIKey}`
    });
    return headers;
}

  static getFormatedDate(date: any): any {
    if (date) {
      date = new Date(date);
      return date.toLocaleString();
    }
    return '';
  }

  static getFormatedDateTime(date: any): string {
    if (date) {
      date = new Date(date);
      return date.toLocaleString();
    }
  }

  static convertToLocalTimezone(date: any): Date {
    const newDate = new Date(date);
    const dt = new Date();
    return new Date(newDate.toUTCString());
  }

  
 
 
  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
  }

  static  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  static convertKendoToPrimePayload(event) {

    let state = {
      skip: event.first,
      take: event.rows,
    };
    let CustomState;
    let send;
    if ((event != null || event != undefined)) {
      var sortDirection;
      if (event.sortField === undefined) {
        sortDirection = [];
      }
      else if (event.sortOrder == 1) {
        sortDirection = [
          {
            "dir": "asc",
            "field": event.sortField
          }
        ]
      }
      else {
        sortDirection = [
          {
            "dir": "desc",
            "field": event.sortField
          }
        ]
      }

      send = {
        group: [],
        skip: event.first,
        sort: sortDirection,
        take: event.rows
      };
      state.skip = event.first;
      state.take = event.rows;
      CustomState = send;
    }
    else {
      CustomState = state;
    }
    let filterData = event;
    let fileds = Object.keys(filterData.filters);
    let neededArray = [];
    let filterInner = [];
    let filterOuter = [];
    let prop;
    let f = 0;
    let i = 0;
    for (prop of fileds) {
      neededArray.push(filterData.filters[prop]);
      f++;
    }
    neededArray.forEach(e => {
      e = e.map(item => {
        return {
          operator: item.matchMode,
          value: item.value,
          logic: item.operator
        };

      });
      e.forEach(e1 => {
        for (prop of fileds) {
          e1['field'] = fileds[i];
        }
        if (e1['operator'] == "equals") {
          e1['operator'] = "eq"
        }
        else if (e1['operator'] == "notEquals") {
          e1['operator'] = "neq"
        }
        else if (e1['operator'] == "notContains") {
          e1['operator'] = "doesnotcontain"
        }
        else if (e1['operator'] == "startsWith") {
          e1['operator'] = "startswith"
        }
        else if (e1['operator'] == "endsWith") {
          e1['operator'] = "endswith"
        }

        //Date filters PrimeNG to Kendo
        else if (e1['operator'] == "dateIs") {
          e1['operator'] = "eq"
        }
        else if (e1['operator'] == "dateIsNot") {
          e1['operator'] = "neq"
        }
        else if (e1['operator'] == "dateBefore") {
          e1['operator'] = "lte"
        }
        else if (e1['operator'] == "dateAfter") {
          e1['operator'] = "gte"
        }

        if (e1['value'] != null) {
          filterInner.push({ "filters": e, "logic": e[0]['logic'] });
        }
      });
      i++;
    });
    filterOuter.push({ "filters": filterInner, "logic": "and" });
    send.filter = filterOuter[0];
    return CustomState;
  }
}

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });
    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }
    return null;
  };
}

export enum Permission {
  Monitor = 'Monitor',
}
