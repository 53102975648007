import { RolePermissionVM } from './RolePermissionVM';

export class MenuMasterVM {
  public menuId: number;
  public displayName: string;
  public codeName: string;
  public route: string;
  public displayOrder: number;
  public icon: string;
  public class: string;
  public isActive: boolean;
  public rolePermissions: RolePermissionVM[];
}
