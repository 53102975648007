import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Common, Permission } from '../common/common';
import { RoleVM } from '../models/responseModel/RoleVM';

@Directive({
  selector: '[PermissionCheck]',
})
export class PermissionCheck implements OnInit {
  @Input('PermissionCheck') menuCode: string;
  @Input() CheckRole: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (Common.loginResponseModel && Common.loginResponseModel.role) {
      const role: RoleVM = Common.loginResponseModel.role.find(
        (x) => x.menuCode === this.menuCode
      );

      if (this.CheckRole === Permission.Monitor) {
        if (role === undefined) {
          this.el.nativeElement.remove();
        } else if (!role.isMonitoring) {
          this.el.nativeElement.remove();
        }
      } else {
        if (role && !role.isControlling) {
          this.el.nativeElement.remove();
        }
      }
    }
  }
}
