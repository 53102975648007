import { NgModule } from '@angular/core';
import { RoleManagementRoutingModule } from './role-management-routing.module';
import { CommonModule } from '@angular/common';
import { RoleManagementComponent } from './role-management.component';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';
import { RoleListComponent } from './role-list/role-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { EVQCSharedModule } from 'src/app/evqc-shared.module';


@NgModule({
  imports: [
    RoleManagementRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    EVQCSharedModule,
  ],
  declarations: [
    RoleManagementComponent,
    AddEditRoleComponent,
    RoleListComponent,
  ],
})
export class RoleManagementModule {}
