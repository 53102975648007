export class Filters {
    field : string;
    operator : string;
    value : any;
}

export class Filter {
    filters : Filters [];
}

export class PrimeFilters {
    filter : Filter;
}



