import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleManagementComponent } from './role-management.component';
import { RoleListComponent } from './role-list/role-list.component';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';

import { MenuCodes } from 'src/app/common/constant';
import { AuthGuard } from 'src/app/services/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: RoleManagementComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        // canActivate: [AuthGuard],
        // data: [{ role: MenuCodes.user_management, isControlling: false }],
        component: RoleListComponent,
      },
      {
        path: 'add',
        // canActivate: [AuthGuard],
        // data: [{ role: MenuCodes.user_management, isControlling: true }],
        component: AddEditRoleComponent,
      },
      {
        path: 'edit/:roleid',
        // canActivate: [AuthGuard],
        // data: [{ role: MenuCodes.user_management, isControlling: true }],
        component: AddEditRoleComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoleManagementRoutingModule {}
