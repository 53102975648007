<h3 class="mt-3 h3-header-lable">Available Roles</h3>

<div class="card mt-3">
  <div class="card-body mb-2 pt-5">

  <div class="row">
    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
      <span style="float: right">
        <a
          class="btn btn-primary btn-rounded mb-2 btn-action-text"
          (click)="onAddNew()"
          [PermissionCheck]="typeCode"
          >Add New
        </a>
      </span>
    </div>
  </div>

    <p-table responsiveLayout="scroll"
      #dt1
      [value]="roleList"
      styleClass="p-datatable-gridlines"
      [rows]="rows"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
     
      [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [totalRecords]="totalRecords"
      [globalFilterFields]="['displayName']"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Created On</th>
          <th [hidden]="commonService.hideIfNotPermission(typeCode)">Action</th>
        </tr>
        <tr>
          <th>
            <p-columnFilter
              type="text"
              matchMode="contains"
              field="displayName"
            ></p-columnFilter>
          </th>
          <th></th>
          <th [hidden]="commonService.hideIfNotPermission(typeCode)"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-dataItem let-expanded="expanded">
        <tr>
          <td>{{ dataItem.displayName }}</td>
          <td>{{ dataItem.createdDate | date: DateTimeFormat }}</td>
          <td [hidden]="commonService.hideIfNotPermission(typeCode)">
            <div *ngIf="dataItem.userroleid !== userRoleId">
              <button
                type="button"
                (click)="onEdit(dataItem)"
                title="Edit"
                class="btn btn-success btn-rounded m-1 action-btn-padding"
              >
                <div class="Editbtn-action-text">
                  <i class="fas fa-edit"></i>
                </div>
              </button>

              <button
                type="button"
                (click)="onDelete(dataItem)"
                class="btn btn-danger btn-rounded m-1 action-btn-padding"
                title="Delete"
              >
                <div class="Editbtn-action-text">
                  <i class="fas fa-trash-alt"></i>
                </div>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>