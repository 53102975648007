import { Filter, PrimeFilters } from "./Filters";

export class  PrimeNgGridState {
    skip?: number;
    /**
     * The number of records to take.
     */
    take?: number;
    /**
     * The descriptors used for sorting.
     */
    sort?: Array<any> = [];
    /**
     * The descriptors used for filtering.
     */
    filter?: Filter;
    /**
     * The descriptors used for grouping.
     */
    group?: Array<any> = [];
    /**
     * Type Of records required
     */
    recordtype?: string;
}