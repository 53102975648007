
export class GridState{
    gridState: any;
    
}


export class PrepaymentHistoryVM extends GridState {    
    srConsumerId:string;
    startdate:number;
    enddate:number;
    
}
