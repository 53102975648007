import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: 'app',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        data: { breadcrumb: 'Home', }
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        data: { breadcrumb: { skip: true, alias: 'mentorAdd' } }
    },
    
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: '**', redirectTo: 'app' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
