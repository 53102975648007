import { MenuMasterVM } from './MenuMasterVM';
import { RoleMasterVM } from './RoleMasterVM';

// export class RolePermissionVM {
//     public userpermissionid: number;
//     public userroleid: number;
//     public menuId: number;
//     public createdDate: Date;
//     public createdBy: number;
//     public updatedDate: Date;
//     public updatedBy: number;
//     public isMonitoring: boolean;
//     public isControlling: boolean;
//     public menuMaster: MenuMasterVM;
//     public userrole: RoleMasterVM;
// }

export class RolePermissionVM {
    public id: number;
    public roleId: number;
    public menuId: number;
    public isMonitoring: boolean;
    public isControlling: boolean;
    public createdBy: number;
    public createdDate: Date;
    public updatedDate: Date;
    public updatedBy: number;

    public menu: MenuMasterVM;
    public role: RoleMasterVM;
}