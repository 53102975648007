import { Injectable } from '@angular/core';
import { Common } from '../common/common';
// import { ResponseModel } from '../models/ResponseModel';
import { Observable } from 'rxjs';
// import { RoleAssignedUsersVM } from '../models/RoleAssignedUsersVM';
// import { UrlParameter, IsEnabled } from '../common/constant';
// import {AppSettings} from '../helper/AppSettings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RoleMasterVM } from '../models/RoleMasterVM';
import { ResponseModelList } from '../models/ResponseModelList';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/ResponseModel';
import { RoleAssignedUsersVM } from '../models/RoleAssignedUsersVM';
import { UrlParameter } from '../common/constant';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  role: RoleMasterVM = new RoleMasterVM();
  forUpdate: boolean;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  options = { headers: this.headers };

  constructor(private http: HttpClient) {}

  addEdit(): Observable<ResponseModel<RoleMasterVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    if (this.forUpdate) {
      return this.http.put<ResponseModel<RoleMasterVM>>(
        ApiUrls.addUpdateRole,
        this.role,
        options
      );
    } else {
      return this.http.post<ResponseModel<RoleMasterVM>>(
        ApiUrls.addUpdateRole,
        this.role,
        options
      );
    }
  }

  getRoles(val: string): Observable<ResponseModelList<RoleMasterVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<RoleMasterVM>>(
      ApiUrls.roleList + '/' + val,
      options
    );
  }


  // getRoles(): Observable<ResponseModelList<RoleResponse>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.get<ResponseModelList<RoleResponse>>(ApiUrls.roleList
  //     .replace(UrlParameter.onlyEnabled, IsEnabled.false), options);
  // }

  // getEnabledRoles(): Observable<ResponseModelList<RoleMasterVM>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.get<ResponseModelList<RoleMasterVM>>(ApiUrls.enabledRoles
  //     .replace(UrlParameter.onlyEnabled, IsEnabled.true), options);
  // }

  countRoleAssignedUsers(roleId: string): Observable<ResponseModel<RoleAssignedUsersVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<RoleAssignedUsersVM>>(ApiUrls.countRoleAssignedUsers
      .replace(UrlParameter.roleId, roleId.toString()), options);
  }

  delete(roleId : string) : Observable<ResponseModel<boolean>>{
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModel<boolean>>(ApiUrls.deleteRoles.replace(UrlParameter.roleId,roleId), options);
  }
}
