import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResponseModel } from '../models/ResponseModel';
import { LoginResponseV2 } from '../models/auth/LoginResponse';
import { ApiUrls } from '../common/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from '../common/common';
import { ExchangeTokenRequest } from '../models/auth/ExchangeTokenRequest';
import { LoginRequest } from '../models/auth/LoginRequest';
import { LocalStorageKeys } from '../common/constant';
import { ExchangeTokenResponse } from '../models/auth/ExchangeTokenResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private onTokenChang = new Subject<boolean>();
  tokenChanged = this.onTokenChang.asObservable();
  public redirectUrl: string;
  constructor(private http: HttpClient) {
  }

  announceTokenChanged(): void {
    this.onTokenChang.next(true);
  }

  login(loginRequest: LoginRequest): Observable<ResponseModel<LoginResponseV2>> {
    const options = Common.getHeader();
    return this.http.post<ResponseModel<LoginResponseV2>>(ApiUrls.login, loginRequest, { headers: options });
  }

  exchangeToken(exchangeTokenRequest: ExchangeTokenRequest): Observable<ResponseModel<ExchangeTokenResponse>> {
    const options = Common.getHeader();
    return this.http.post<ResponseModel<ExchangeTokenResponse>>(ApiUrls.exchangeToken, exchangeTokenRequest, { headers: options });
  }

  logout(): void {
    localStorage.removeItem(LocalStorageKeys.loginResponse);
  }


  testTCPDataSend(data2){
    var data = JSON.stringify({
      "message": data2
    });
    
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
      }
    });
    
    xhr.open("POST", "http://localhost:85");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("Connection", "Keep-Alive");
    
    xhr.send(data);
    
   //const options = Common.getHeaderEle();
   // return this.http.post<any>("http://localhost:86/SendData",data2 ,{ headers: options });
  }

}
