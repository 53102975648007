import { Injectable } from '@angular/core';
import { ApiUrls } from '../common/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = { headers: headers };
    return this.http.post<any>(ApiUrls.WebAPIBaseUrl, "grant_type=password&username=ritesh.prajapati@slscorp.com&password=12345&client_id=nebulae&client_secret=nebu!@e", options);
  }
}
