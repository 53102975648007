import { ServerTypes, UrlParameter } from './constant';

declare var WebAPIBaseUrl: string; //serverType 'verdeMobility'
declare var PythonScriptURL: string;
declare var WebAPIBaseUrlQC: string;  //serverType 'sls'

export class ApiUrls {
  static WebAPIBaseUrl: string = WebAPIBaseUrl;
  static baseUrl: string = WebAPIBaseUrl;
  static pythonScriptUrl: string = PythonScriptURL;

  static setBaseUrl(serverType: string): void {
    this.baseUrl = serverType === ServerTypes.SLS ? WebAPIBaseUrlQC : WebAPIBaseUrl;
  }

  //#region AuthenticateController
  static get login() { return `${this.baseUrl}/v1/Login`; }
  static get exchangeToken() { return `${this.baseUrl}/v1/Login/exchange-token`; }

  //#endregion

  //#Region Device Configuration
  static factoryReset = `${ApiUrls.pythonScriptUrl}/Factory_Reset`;
  static getDeviceConfig = `${ApiUrls.pythonScriptUrl}/Factory_Reset_Status?cpS(){return${UrlParameter.cpSr}`;
  static getDeviceMacAddress = `${ApiUrls.pythonScriptUrl}/get_mac_address`;
  static getIMEINumber = `${ApiUrls.pythonScriptUrl}/get_imei_number`;
  static changeFirmware = `${ApiUrls.pythonScriptUrl}/change_firmware`;
  static get setDeviceConfigOnServer() { return `${this.baseUrl}/v1/ProductSerial/setConfigurationData`; }
  static get getDeviceConfigFromServer() { return `${this.baseUrl}/v1/ProductSerial/getConfigurationData/${UrlParameter.cpSr}`; }
  static get updateDeviceMac() { return `${this.baseUrl}/v1/ProductSerial/updateMac/${UrlParameter.productSerialNumberSkey}`; }
  static get saveDeviceIMEI() { return `${this.baseUrl}/v1/ProductSerial/updateImei/${UrlParameter.productSerialNumberSkey}`; }
  //#endregion

  //#Region AutoFunctionalTest
  static autoFunctionalTest = `${ApiUrls.pythonScriptUrl}/auto_functional_test`;
  //#endRegion

  //#region UserController
  static get userList() { return `${this.baseUrl}/v1/users`; }
  // static get user(){return `${this.baseUrl}/v1/auth/web/register`;}
  static get updateUser() { return `${this.baseUrl}/v1/users`; }

  // static get userListPagination(){return `${this.baseUrl}/v2/user`;}
  // static get availableUsersForStartSessionList(){return `${this.baseUrl}/v1/user/available-for-charging`;}
  static get enabledUsers() { return `${this.baseUrl}/v1/users/${UrlParameter.onlyEnabled}`; }
  //static get deleteUser(){return `${this.baseUrl}/v1/user/${UrlParameter.userId}`;}
  // static get userProfile(){return `${this.baseUrl}/v1/user/profile`;}
  // static get changePassword(){return `${this.baseUrl}/v1/user/change-password`;}
  static get forgotPassword() { return `${this.baseUrl}/v1/users/forgot-password`; }
  static get resetPassword() { return `${this.baseUrl}/v1/users/reset-password`; }

  //#endregion

  //#region RoleController
  static get roleList() { return `${this.baseUrl}/v1/role`; }
  static get countRoleAssignedUsers() { return `${this.baseUrl}/v1/role/${UrlParameter.roleId}/count-role-assigned-users`; }
  static get addUpdateRole() { return `${this.baseUrl}/v1/role`; }
  static get enabledRoles() { return `${this.baseUrl}/v1/role/${UrlParameter.onlyEnabled}`; }
  static get deleteRoles() { return `${this.baseUrl}/v1/role/${UrlParameter.roleId}`; }

  //#endregion

  //#region MenuController
  static get menu() { return `${this.baseUrl}/v1/menu`; }
  static get menuByRoleId2() { return `${this.baseUrl}/v1/menu/${UrlParameter.roleId}`; }
  static get menuByRoleId() { return `${this.baseUrl}/v1/menu/${UrlParameter.roleId}`; }
  //#endregion


  static get templateList() { return `${this.baseUrl}/v1/sms-template`; }
  static get templateAddEdit() { return `${this.baseUrl}/v1/sms-template`; }
  static get templateGetById() { return `${this.baseUrl}/v1/sms-template`; }
  static get templateType() { return `${this.baseUrl}/v1/sms-template/sms-types`; }
  static get addUser() { return `${this.baseUrl}/v1/Users/AddNewUser`; }

  static get deleteUser() { return `${this.baseUrl}/v1/Users/${UrlParameter.userId}`; }


  //#region TestCase
  static get getTestCasesByProductAndType() { return `${this.baseUrl}/v1/TestCase/${UrlParameter.productId}/${UrlParameter.productType}/${UrlParameter.chargerCategory}`; }



  static get getTestCasesResult() { return `${this.baseUrl}/v1/TestResult/GetTestCaseResult/${UrlParameter.srTestMasterId}`; }
  static get GetLatestTestCaseResult() { return `${this.baseUrl}/v1/TestResult/GetLatestTestCaseResult/${UrlParameter.testCaseType}/${UrlParameter.srTestDeviceId}`; }

  //#endregion

  //#region TestResult
  static get SaveTestCaseResult() { return `${this.baseUrl}/v1/TestResult`; }
  static get SaveTestCaseResultForAdd() { return `${this.baseUrl}/v1/TestResult/AddTestResult`; }
  static get SaveTestCaseResultForUpdate() { return `${this.baseUrl}/v1/TestResult/UpdateTestResult`; }
  static get UpdateTestCaseResult() { return `${this.baseUrl}/v1/TestcaseResult`; }
  static get GetPhysicalFunctionalTestCase() { return `${this.baseUrl}/v1/TestResult/GetPhysicalFunctionalTestCase/${UrlParameter.testCaseType}/${UrlParameter.srTestDeviceId}/${UrlParameter.isForAdd}`; }

  static get GetTestDeviceList() { return `${this.baseUrl}/v1/TestResult/GetTestDevice`; }

  static get GetTestMasterList() { return `${this.baseUrl}/v1/TestResult/GetTestMaster`; }
  static get CreateChargePoint() { return `${this.baseUrl}/v1/TestResult/CreateChargepoint/${UrlParameter.srTestDeviceId}`; }


  static get GetTestDeviceReport() { return `${this.baseUrl}/v1/Report/GetTestDeviceReport`; }

  //#endregion

  //#region Dashboard

  static get getDispatchedDeviceCount() { return `${this.baseUrl}/v1/Dashboard/dispatched-device-count`; }
  static get getPassedDeviceCount() { return `${this.baseUrl}/v1/Dashboard/passed`; }
  static get getFailedDeviceCount() { return `${this.baseUrl}/v1/Dashboard/failed`; }
  static get getDashboardData() { return `${this.baseUrl}/v1/Dashboard/getDashboardData`; }


  //#endregion

  //#region ProductSerialData
  static get postSerialData() { return `${this.baseUrl}/v1/ProductSerial/import`; }
  static get downloadExcelFormat() { return `${this.baseUrl}/v1/ProductSerial/product-serial-excel-format`; }
  static get getProductDetails() { return `${this.baseUrl}/v1/ProductSerial`; }
  static get updateProductSerialData() { return `${this.baseUrl}/v1/ProductSerial/update`; }
  static get deleteProductSerialData() { return `${this.baseUrl}/v1/ProductSerial/delete/${UrlParameter.productSerialSkey}`; }
  //#endregion
  static get getSerialDataForForm() { return `${this.baseUrl}/v1/ProductSerial/getSerialDataForForm/${UrlParameter.productserialDataForFunctional}`; }
  static get getProductDetailBySkey() { return `${this.baseUrl}/v1/ProductDetail/${UrlParameter.skey}`; }
  static get getAllProducts() { return `${this.baseUrl}/v1/ProductDetail`; }


  //#region ChargePointEvseAction

  static get startSessionChargePointEvseAction() { return `${this.baseUrl}/v1/ChargePointEvseAction/start-session`; }
  static get stopSessionChargePointEvseAction() { return `${this.baseUrl}/v1/ChargePointEvseAction/stop-session/${UrlParameter.chargePointSessionId}`; }
  static get force_stopSessionChargePointEvseAction() { return `${this.baseUrl}/v1/ChargePointEvseAction/force-stop-session/${UrlParameter.chargePointSessionId}`; }


  //#endregion

  //#region ChargePointEvseAction

  static get addTriageDetail() { return `${this.baseUrl}/v1/Triage/add-detail`; }
  static get updateTriageDetail() { return `${this.baseUrl}/v1/Triage/update-detail`; }
  static get getTriageComments() { return `${this.baseUrl}/v1/Triage/view-comments/${UrlParameter.srTestMasterId}`; }
  static get getAllTriageComments() { return `${this.baseUrl}/v1/Triage/view-all-comments/${UrlParameter.srTestDeviceId}`; }
}
