import { Injectable } from '@angular/core';
import { Common } from '../common/common';

import { RoleVM } from '../models/responseModel/RoleVM';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  index = 1;

  public hideIfNotPermission(menuCode): boolean {
    // tslint:disable-next-line: triple-equals

    if (Common.loginResponseModel && Common.loginResponseModel.role) {
      const role: RoleVM = Common.loginResponseModel.role.find(
        (x) => x.menuCode == menuCode
      );
      if (role) {
        return !role.isControlling;
      } else {
        return true;
      }
    }
  }

  // public openStatusDialogForm(component: any, dtitle: string, width: number): void {
  //     const dialogRef = this.dialogService.open({
  //         content: component,
  //         title: dtitle,
  //         width,
  //         minWidth: 250
  //     });
  //     dialogRef.result.subscribe((data: any) => {
  //         if (data.primary) {
  //         }
  //     });
  // }

  // getFormatedDateTime(datetime): any {
  //     return Common.getFormatedDateTime(datetime);
  // }

  // getClientLogo(): string {
  //     let logoFilePath;
  //     if (Common.loginResponseModel.clientKey) {
  //         // this.clientService.getClientLogo(Common.loginResponseModel.clientKey).subscribe(
  //         //     (res) => {
  //         //         logoFilePath = res.data.logoFilePath;
  //         //         return logoFilePath;
  //         //     }
  //         // );
  //     }
  //     return logoFilePath;
  // }
}
