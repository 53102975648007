import { RolePermissionVM } from './RolePermissionVM';

// Find all classes with a name ending with Model
export class RoleMasterVM {
  public roleId: string;
  public displayName: string;
  public codeName: string;
  public isActive: boolean;
  public isDeleted: boolean;
  public createdDate: Date;
  public createdBy: number;
  public updatedDate: Date;
  public updatedBy: number;
  public clientName: string;
  // public rolePermission: RolePermissionVM[];
  public rolePermissions: RolePermissionVM[];

  public createdDateFilter: Date;
  public userName: string;
}
