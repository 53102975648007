import { Component, NgZone, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Common } from './common/common';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LocalStorageKeys, RouteUrl} from './common/constant';
import { LoaderService } from './services/loader.service';
import { Title } from '@angular/platform-browser';

declare var Clients: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'EV-ProdQC-Portal';
    nodeObj: any;
    private subscription: Subscription;
    public message: string;
    asyncLoadCount = 0;
    constructor(
        public router: Router,
        authService: AuthService,
        private ngZone: NgZone,
        private titleService: Title,
        public loaderService: LoaderService) {
        const loginResponse = localStorage.getItem(LocalStorageKeys.loginResponse);
        if (loginResponse && !Common.loginResponseModel) {
            Common.loginResponseModel = JSON.parse(loginResponse);
        }
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationStart) {
                if (ev.url !== '/auth/login' && ev.url !== '/auth/forgot-password' && ev.url !== '/auth/reset-password') {
                    if (!Common.loginResponseModel) {
                        authService.logout();
                    }
                }
            }
        });
        
    }
    user: any;

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler() {
        window.localStorage.removeItem('tempdata');
    }

    ngOnInit(): void {
       
    }

   

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    
}

