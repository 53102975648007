<h3 class="mt-3 h3-header-lable">Role</h3>
<div class="card mt-3">
  <div class="card-body mb-2 pt-5">
    <div class="row">
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <span style="float: right">
          <a
            (click)="backToList()"
            class="btn btn-primary btn-rounded mb-2 btn-action-text float-md-right"
          >
            Back
          </a>
        </span>
      </div>
    </div>

    <form
      class="form-horizontal style-form"
      [formGroup]="roleFormGroup"
      (ngSubmit)="onSave()"
    >
      <div class="row">
        <div class="col col-md-6">
          <div class="form-group">
            <label for="inputMacname"
              >Name<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="roleService.role.displayName"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  f['name'].invalid && (f['name'].dirty || f['name'].touched)
              }"
            />

            <div
              *ngIf="name.invalid && (name.dirty || name.touched || isSubmit)"
              class="invalid-feedback d-block"
            >
              Name is required.
            </div>

            <!-- <div *ngIf="isSubmit || name!.errors" class="invalid-feedback">
                          <span *ngIf="isSubmit || name!.errors['required']">Name is required.</span>                        
                      </div> -->
          </div>
        </div>
      </div>
      <div class="row" *ngIf="roleService.role.rolePermissions">
        <div class="col-lg-8 mt-2">
          <p-table responsiveLayout="scroll"
            #dt1
            [value]="roleService.role.rolePermissions"
            styleClass="p-datatable-gridlines"
            [loading]="loading"
            [paginator]="false"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Menu</th>
                <th>
                  <label class="custom-chk">
                    <input
                      type="checkbox"
                      [checked]="
                        isSelectAllMonitoring || isSelectAllControlling
                      "
                      (click)="selectAllMonitoring()"
                      [disabled]="isSelectAllControlling"
                    />
                    <span class="ms-2">Monitoring</span>
                    <span
                      class="checkmark"
                      [class.monitoring-disabled]="isSelectAllControlling"
                    ></span>
                  </label>
                </th>
                <th>
                  <label class="custom-chk">
                    <input
                      type="checkbox"
                      [checked]="isSelectAllControlling"
                      (click)="selectAllControlling()"
                    />
                    <span class="ms-2">Controlling</span>
                    <span class="checkmark"></span>
                  </label>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-dataItem let-expanded="expanded">
              <tr>
                <td>{{ dataItem.menu?.displayName }}</td>
                <td>
                  <label
                    class="custom-chk"
                    *ngIf="getAdminRoleMonitoring(dataItem)"
                  >
                    <!-- <input type="checkbox" [checked]="data.isMonitoring || data.isControlling" (change)="monitoringChange(data)" [disabled]="data.isControlling || data.menuMaster.codeName==dashboardMenu"> -->
                    <input
                      type="checkbox"
                      [checked]="
                        dataItem.isMonitoring || dataItem.isControlling
                      "
                      (change)="monitoringChange(dataItem)"
                    />
                    <span
                      class="checkmark"
                      [class.monitoring-disabled]="dataItem.isControlling"
                      style="top: -10px"
                    ></span>
                  </label>
                </td>
                <td>
                  <label
                    class="custom-chk"
                    *ngIf="getAdminRoleControlling(dataItem)"
                  >
                    <!-- <input type="checkbox" [checked]="data.isControlling" (change)="controllingChange(data)" [disabled]="data.menuMaster.codeName==dashboardMenu"> -->
                    <input
                      type="checkbox"
                      [checked]="dataItem.isControlling"
                      (change)="controllingChange(dataItem)"
                    />
                    <span
                      class="checkmark"
                      [class.monitoring-disabled]="
                        dataItem.menu.codeName == dashboardMenu
                      "
                      style="top: -10px"
                    >
                    </span>
                  </label>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col text-center">
          <div class="form-group">
            <div class="row" style="float: right">
              <div class="col-lg-12">
                <button
                  [disabled]="!roleFormGroup.valid"
                  class="btn btn-primary btn-rounded btn-action-text float-md-right"
                  type="submit"
                >
                  <span *ngIf="!isEdit">Save</span>
                  <span *ngIf="isEdit">Update</span>
                </button>
                <button
                  *ngIf="!isEdit"
                  type="button"
                  class="btn btn-danger btn-rounded ms-2 btn-action-text"
                  (click)="reset()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

