import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { RoleMasterVM } from 'src/app/models/RoleMasterVM';
import { HESDateTime, MenuCodes, RouteUrl } from 'src/app/common/constant';
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { RoleAssignedUsersVM } from 'src/app/models/RoleAssignedUsersVM';
import { Filter, Filters, PrimeFilters } from 'src/app/models/Filters';
import { GridState } from 'src/app/models/DeviceListFilterVM';
import { PrimeNgGridState } from 'src/app/models/PrimeNgGridState';
import { Common } from 'src/app/common/common';
import { StatusType } from 'src/app/models/StatusType';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
})
export class RoleListComponent implements OnInit {
  roleList: RoleMasterVM[];
  exportRoleList: RoleMasterVM[];
  excelExportRoleList: RoleMasterVM[];
  isDisableActive: boolean = false;
  isDisabled: boolean = false;
  typeCode: string = MenuCodes.user_management;

  public DateTimeFormat: string = HESDateTime.DateTimeFormat;
  public state: any = {
    skip: 0,
    take: 25,
  };

  loading: boolean = false;
  public userRoleId = Common.loginResponseModel.roleId;

  constructor(
    private roleService: RoleService,
    private router: Router,
    public commonService: CommonService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.roleService.getRoles('true').subscribe((result) => {
      console.log(result);

      this.loading = false;
      let ResponseModel: ResponseModel<RoleMasterVM[]> = result;
      this.roleList = ResponseModel.data;
      this.totalRecords = this.roleList.length;
      this.populateGridData();
    });
  }

  populateGridData() {
    this.exportRoleList = this.roleList;
  }

  onAddNew() {
    this.roleService.forUpdate = false;
    this.roleService.role = new RoleMasterVM();
    this.router.navigateByUrl(RouteUrl.addRole);
  }

  onEdit(roleMasterVM: RoleMasterVM) {
    this.roleService.forUpdate = true;
    console.log('onEdit');    
    console.log(roleMasterVM);    
    this.roleService.role = roleMasterVM;
    this.router.navigateByUrl(RouteUrl.addRole);
  }

  onDelete(roleMasterVM: RoleMasterVM) {
    this.roleService.role = roleMasterVM;
    this.loading = true;
    this.roleService
      .countRoleAssignedUsers(this.roleService.role.roleId).subscribe((result) => {
        let response = <ResponseModel<RoleAssignedUsersVM>>result;
        const roleAssignedUsersVM: RoleAssignedUsersVM = response.data;
        if (roleAssignedUsersVM.assignedUsers > 0) {
          this.loading = false;
          const userNoun = roleAssignedUsersVM.assignedUsers > 1 ? 's' : '';
          Swal.fire({
            html: `<strong>${roleMasterVM.displayName}</strong> role cannot be deleted,
          it is assigned to ${roleAssignedUsersVM.assignedUsers} user${userNoun}`,
            icon: 'warning',
            confirmButtonColor: '#00ac69',
            cancelButtonColor: '#d33',
          });
        } else {
          this.loading = false;
          Swal.fire({
            title: '',
            html: `Are you sure want to delete <strong>${roleMasterVM.displayName}</strong> role`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00ac69',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then((userResponse) => {
            if (userResponse.value) {
              this.loading = true;
              this.roleService.delete(this.roleService.role.roleId).subscribe((deleteResponse) => {
                
                this.loading = false;
                if (deleteResponse.statusType == StatusType.Success) {
                  if (deleteResponse.data) {
                    this.toastrService.success('Record deleted successfully.');
                    const index = this.roleList.indexOf(
                      this.roleService.role,
                      0
                    );
                    this.roleList.splice(index, 1);
                    this.populateGridData();
                  }
                }
              });
            }
          });
        }
      });
  }

  @ViewChild('dt1') dtTable;
  filters = new Filter();
  primefilters = new PrimeFilters();
  rows = 25;
  totalRecords: number = 0;
  filterPrimeNgChange(event) {
    this.sort = [];
    if (event.filters) {
      const filter = new Array<Filters>();
      for (const key in event.filters) {
        if (Object.prototype.hasOwnProperty.call(event.filters, key)) {
          const element = event.filters[key];
          if (element.value) {
            if (element.matchMode && element.matchMode == 'equals')
              element.matchMode = 'eq';

            var f = new Filters();
            f.field = key;
            f.operator = element.matchMode;
            f.value = element.value;
            filter.push(f);
          }

          this.filters.filters = filter;
          this.primefilters.filter = this.filters;
          if (event.sortField) {
            this.sort = this.setSortOrder(event);
          }
        }
      }
      this.rows = event.rows;
      this.setNgPrimeGridState(event.first);
    }
  }

  primeNgGridState = new PrimeNgGridState();
  primeNgstate = new GridState();
  sort: any = [];

  setNgPrimeGridState(skip) {
    this.primeNgGridState.sort = [];
    this.primeNgGridState.filter = this.primefilters.filter;
    // this.primeNgGridState.group = this.state.group;
    this.primeNgGridState.skip = skip;
    this.primeNgGridState.take = this.rows;
    if (this.sort.length > 0) {
      this.primeNgGridState.sort.push(JSON.parse(this.sort));
    }
    this.primeNgstate.gridState = this.primeNgGridState;
  }

  setSortOrder(event) {
    if (event.sortOrder) {
      let dir;
      if (event.sortOrder == 1) {
        dir = 'asc';
      } else if (event.sortOrder == -1) {
        dir = 'desc';
      }
      var data = {
        dir: dir,
        field: event.sortField,
      };
      return JSON.stringify(data);
    }
  }
}
