import { CommonModule, DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from './services/auth-guard.service';
import { ErrorInterceptor } from './helpers/ErrorInterceptor';

import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ToastrModule } from 'ngx-toastr';
import { Common } from './common/common';
import { NgxImageCompressService } from 'ngx-image-compress';
import { RoleManagementModule } from './pages/users/role/role-management.module';
import { EVQCSharedModule } from './evqc-shared.module';
import {PanelMenuModule} from 'primeng/panelmenu';

import { NgxPrinterModule, ngxPrintMarkerPosition } from 'ngx-printer';
declare var MQTTHost;
declare var MQTTPort;
declare var MQTTprotocol;
declare var MQTTClientId;

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: MQTTHost,
  port: MQTTPort,
  protocol: MQTTprotocol,
  clientId: MQTTClientId + Common.newGuid(),
  
};

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    EVQCSharedModule,
    RoleManagementModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    PanelMenuModule,
    NgxPrinterModule.forRoot({printOpenWindow: false}),
  ],
  declarations: [AppComponent],
  providers: [
    Title,
    AuthGuard,
    DatePipe,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
