import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageKeys, RouteUrl } from '../common/constant';
import { AuthService } from './auth.service';
import { Common } from '../common/common';
import { LoginResponseV2 } from '../models/auth/LoginResponse';
import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (Common.loginResponseModel === null || Common.loginResponseModel === undefined) {
    //   Common.loginResponseModel = JSON.parse(localStorage.getItem(LocalStorageKeys.loginResponse));
    // }
    // const userData: LoginResponseV2 = Common.loginResponseModel;
    const userData: LoginResponseV2 = JSON.parse(localStorage.getItem(LocalStorageKeys.loginResponse));
    if (userData) {
      if (route.data.roles && route.data.roles.indexOf(userData.role) === -1) {
        this.router.navigateByUrl(RouteUrl.dashboard);
        return false;
      }
      return true;
    }
    else {
      this.router.navigateByUrl(RouteUrl.login);
      return false;
    }
  }
}
