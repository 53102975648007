import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
// import { commonHelper } from '../../../../core/helper/commonHelper';
import { ToastrService } from 'ngx-toastr';
import { MenuCodes, RouteUrl } from 'src/app/common/constant';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { MenuMasterVM } from 'src/app/models/MenuMasterVM';
import { RoleVM } from 'src/app/models/responseModel/RoleVM';
import { RoleAssignedUsersVM } from 'src/app/models/RoleAssignedUsersVM';
import { RoleMasterVM } from 'src/app/models/RoleMasterVM';
import { RoleService } from 'src/app/services/role.service';
import { MenuService } from 'src/app/services/menu.service';
import { Common } from 'src/app/common/common';
import { StatusType } from 'src/app/models/StatusType';
import { RolePermissionVM } from 'src/app/models/RolePermissionVM';

@Component({
  selector: 'add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.css'],
})
export class AddEditRoleComponent implements OnInit {
  isEdit: boolean = false;
  isSelectAllMonitoring = false;
  loading: boolean = false;
  isSelectAllControlling = false;
  dashboardMenu = MenuCodes.dashboard;
  roleFormGroup: FormGroup;
  isSubmit: boolean = false;

  constructor(
    public roleService: RoleService,
    private menuService: MenuService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateroute: ActivatedRoute, 
  ) {}

  


  ngOnInit() {
    console.log(this.roleService.role);

    this.roleFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
    });
    if (this.roleService.forUpdate) {
      this.f['name'].setValue(this.roleService.role.displayName);
      this.isEdit = true;
    }
    
    this.getMenuList();
    // this.f.isActive.setValue(!this.roleService.role.isDisabled);
    
  }
  get name(): AbstractControl {
    return this.roleFormGroup.get('name');
  }

  getMenuList() {
    this.loading = true;
    this.menuService.getMenuByRole().subscribe((result) => {
      let response: ResponseModel<any> = result;

      if (response && response.data && response.data.length > 0) {
        const menuList: MenuMasterVM[] = response.data;
        if (!this.roleService.role.rolePermissions) {
          this.roleService.role.rolePermissions = [];
        }

        menuList.forEach((menu) => {
          const rolePermission: RolePermissionVM =
            this.roleService.role.rolePermissions.find(
              (x) => x.menuId === menu.menuId
            );
          if (!rolePermission) {
            const rolePermissionVM: RolePermissionVM = new RolePermissionVM();
            rolePermissionVM.menuId = menu.menuId;
            rolePermissionVM.menu = menu;
            this.roleService.role.rolePermissions.push(rolePermissionVM);
          } else {
            rolePermission.menu = menu;
          }
        });
        this.roleService.role.rolePermissions.sort(
          (a, b) => a.menu.displayOrder - b.menu.displayOrder
        );
        this.checkControllingForSelectAll();
        this.loading = false;
      }
      this.loading = false;
    });
  }
  getMenuListByRoleId(){

  }

  // assignDashboardRole() {
  //   const role: RolePermissionVM = this.roleService.role.rolePermission.find(x => x.menuMaster.codeName === this.dashboardMenu);
  //   role.isControlling = true;
  //   role.isMonitoring = true;
  // }

  selectAllMonitoring() {
    this.isSelectAllMonitoring = !this.isSelectAllMonitoring;
    if (this.isSelectAllMonitoring) {
      this.roleService.role.rolePermissions.forEach((permission) => {
        if (this.getAdminRoleMonitoring(permission)) {
          permission.isMonitoring = true;
        }
      });
    } else {
      this.roleService.role.rolePermissions.forEach((permission) => {
        if (!permission.isControlling) {
          permission.isMonitoring = false;
        }
      });
    }
    //this.assignDashboardRole();
  }

  selectAllControlling() {
    this.isSelectAllControlling = !this.isSelectAllControlling;
    if (this.isSelectAllControlling) {
      this.roleService.role.rolePermissions.forEach((permission) => {
        if (this.getAdminRoleControlling(permission)) {
          permission.isMonitoring = true;
          permission.isControlling = true;
          permission.menuId = permission.menu.menuId;
        }
      });
    } else {
      this.roleService.role.rolePermissions.forEach((permission) => {
        permission.isMonitoring = true;
        permission.isControlling = false;
        permission.menuId = permission.menu.menuId;
      });
    }

    this.checkMonitoringForSelectAll();
  }

  checkMonitoringForSelectAll() {
    if (
      Common.loginResponseModel.role.filter((x) => x.isMonitoring === true)
        .length ===
      this.roleService.role.rolePermissions.filter(
        (x) => x.isMonitoring === true
      ).length
    ) {
      this.isSelectAllMonitoring = true;
    } else {
      this.isSelectAllMonitoring = false;
    }
    //this.assignDashboardRole();
  }

  checkControllingForSelectAll() {
    if (
      Common.loginResponseModel.role.filter((x) => x.isControlling === true)
        .length ===
      this.roleService.role.rolePermissions.filter(
        (x) => x.isControlling === true
      ).length
    ) {
      this.isSelectAllMonitoring = true;
      this.isSelectAllControlling = true;
    } else {
      this.isSelectAllMonitoring = false;
      this.isSelectAllControlling = false;
    }
    this.checkMonitoringForSelectAll();
  }

  monitoringChange(rolePermission: RolePermissionVM) {
    rolePermission.isMonitoring = !rolePermission.isMonitoring;
    //this.checkMonitoringForSelectAll();
  }

  controllingChange(rolePermission: RolePermissionVM) {
    if (!rolePermission.isControlling) {
      rolePermission.isMonitoring = true;
    }
    rolePermission.isControlling = !rolePermission.isControlling;
    //this.checkControllingForSelectAll();
  }

  getAdminRoleMonitoring(rolePermintion: RolePermissionVM) {
    const role: RoleVM = Common.loginResponseModel.role.find(
      (x) => x.menuCode === rolePermintion.menu?.codeName
    );
    if (role) {
      if (!role.isMonitoring) {
        rolePermintion.isMonitoring = false;
      }
      return role.isMonitoring;
    } else {
      return false;
    }
  }

  getAdminRoleControlling(rolePermintion: RolePermissionVM) {
    const role: RoleVM = Common.loginResponseModel.role.find(
      (x) => x.menuCode === rolePermintion.menu?.codeName
    );
    if (role) {
      if (!role.isControlling) {
        rolePermintion.isControlling = false;
      }
      return role.isControlling;
    } else {
      return false;
    }
  }

  get f() {
    return this.roleFormGroup.controls;
  }

  backToList() {
    this.isEdit = false;
    this.router.navigateByUrl(RouteUrl.role);
    this.isEdit = false;
    this.isSubmit = false;
  }

  checkPermissionState(rolePermission: RolePermissionVM) {
    return (
      rolePermission.isMonitoring === true ||
      rolePermission.isControlling === true
    );
  }

  onSave() {
    const isAtLeastPermissionChecked =
      this.roleService.role.rolePermissions.some(this.checkPermissionState);
    if (!isAtLeastPermissionChecked) {
      // this.showRolePermissionError();
      Swal.fire({
        title: 'Validation',
        text: 'Please check at least one role permission',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
      return;
    }
    if (!this.roleFormGroup.invalid) {
      // this.roleService.role.isDisabled = !this.f.isActive.value;
      if (this.roleService.forUpdate) {
        this.roleService
          .countRoleAssignedUsers(this.roleService.role.roleId.toString())
          .subscribe((result) => {
            let CheckRoleResponse: ResponseModel<RoleAssignedUsersVM> = result;
            const roleAssignedUsersVM: RoleAssignedUsersVM =
              CheckRoleResponse.data;

            if (roleAssignedUsersVM.assignedUsers > 0) {
              const userNoun = roleAssignedUsersVM.assignedUsers > 1 ? 's' : '';
              Swal.fire({
                title: 'Update Role',
                html: `Are you sure want to update <strong>${this.roleService.role.displayName}
                    </strong> role? It is assigned to ${roleAssignedUsersVM.assignedUsers} user${userNoun}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00ac69',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true,
              }).then((userResponse) => {
                if (userResponse.value) {
                  this.roleService.addEdit().subscribe(
                    (result) => {
                      let response: ResponseModel<RoleMasterVM> = result;
                      if (response && response.data) {
                        this.toastrService.success(
                          'Record saved successfully.'
                        );
                        this.router.navigateByUrl(RouteUrl.role);
                        this.roleService.role = new RoleMasterVM();
                        this.roleService.forUpdate = false;
                        this.getMenuList();
                      } else {
                        this.loading = false;
                        this.toastrService.error(
                          response.errors[0].message.join(', ')
                        );
                      }
                    },
                    (errorResponse: HttpErrorResponse) => {
                      this.loading = false;
                      this.toastrService.error(errorResponse.error[0].message);
                    }
                  );
                }
              });
            } else {
              this.roleService.addEdit().subscribe((result) => {
                let response: ResponseModel<RoleMasterVM> = result;
                if (response && response.statusType == StatusType.Success) {
                  this.toastrService.success('Record saved successfully.');
                  this.router.navigateByUrl(RouteUrl.role);
                  this.roleService.role = new RoleMasterVM();
                  this.roleService.forUpdate = false;
                  this.getMenuList();
                } else {
                  this.toastrService.error(
                    response.errors[0].message.join(', ')
                  );
                }
              });
            }
          });
      } else {
        this.roleService.addEdit().subscribe(
          (result) => {
            let response: ResponseModel<RoleMasterVM> = result;
            if (response && response.statusType == StatusType.Success) {
              this.toastrService.success('Record saved successfully.');
              this.router.navigateByUrl(RouteUrl.role);
              this.roleService.role = new RoleMasterVM();
              this.roleService.forUpdate = false;
              this.getMenuList();
            } else {
              this.loading = false;
              this.toastrService.error(response.errors[0].message.join(', '));
            }
          },
          (errorResponse: HttpErrorResponse) => {
            console.log(errorResponse);
            if (errorResponse.error && errorResponse.error.message) {
              Swal.fire('Error!', errorResponse.error.message, 'error');
            } else {
              Swal.fire(
                'Error!',
                'Failed to delete Record. Please contact Administrator',
                'error'
              );
            }
          }
        );
      }
    }
  }

  /* public showRolePermissionError(): void {
    this.notificationService.show({
      content: 'Please select at least one role permission',
      hideAfter: 2000,
      position: { horizontal: 'center', vertical: 'bottom' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'error', icon: true },
      cssClass: ['font-weight-bold', 'mb-2']
    });
  } */

  reset() {
    this.isEdit = false;
    this.isSubmit = true;
    this.roleFormGroup.reset();
    this.isSelectAllControlling = false;
    this.isSelectAllMonitoring = false;
    if (this.roleService.role.rolePermissions) {
      this.roleService.role.rolePermissions.forEach((x) => {
        x.isControlling = false;
        x.isMonitoring = false;
      });
    }
  }
}
