import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-management',
  template: `<router-outlet></router-outlet> `,
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
