import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoaderService } from '../services/loader.service';
import { Common } from '../common/common';
import { ExchangeTokenRequest } from '../models/auth/ExchangeTokenRequest';
import { switchMap } from 'rxjs/operators';
import { ErrorCode, RouteUrl, LocalStorageKeys, ErrorMessage, ToastMessages } from '../common/constant';
import { Location } from '@angular/common';
import { ToastService } from '../services/toast.service';
import { StatusType } from '../models/StatusType';
import { LoginService } from '../services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router,
    public loaderService: LoaderService) { }
  apiCount: number = 0;

  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isRefreshingToken: boolean;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.apiCount++;
    if (request.url.indexOf('notification/seen') === -1) {
      this.loaderService.show();
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === ErrorCode.badRequest) {
          if (error.error && error.error.message && error.error.message === ErrorMessage.invalidRefreshToken) {
            this.authService.logout();
            this.router.navigateByUrl(RouteUrl.login);
          } else if (error.error && error.url.indexOf('login') < 0) {
            Swal.fire(
              'Error!',
              error.error.message,
              'error'
            );
          }
        } else if (error.status === ErrorCode.unauthorizedRequest) {
          if (request.url.indexOf('exchange-token') == -1) {
            if (Common.loginResponseModel) {
              if (!this.isRefreshingToken) {                
                this.isRefreshingToken = true;
                const exchangeTokenRequest: ExchangeTokenRequest = new ExchangeTokenRequest();
                exchangeTokenRequest.accessToken = Common.loginResponseModel.accessToken;
                exchangeTokenRequest.refreshToken = Common.loginResponseModel.refreshToken;
                exchangeTokenRequest.userId = Common.loginResponseModel.userId;
                this.tokenSubject.next(null);
                return this.authService.exchangeToken(exchangeTokenRequest).pipe(switchMap(response => {
                  if (response) {
                    localStorage.setItem(LocalStorageKeys.loginResponse, JSON.stringify(response.data));
                    
                    //Common.loginResponseModel = response.data;
                                    Common.loginResponseModel.accessToken = response.data.accessToken;
                                    Common.loginResponseModel.refreshToken = response.data.refreshToken;
                                    Common.loginResponseModel.tokenType = response.data.tokenType;

                    this.tokenSubject.next(Common.loginResponseModel.accessToken);
                    return next.handle(this.addToken(request, Common.loginResponseModel.accessToken));
                  }
                  this.authService.logout();
                }), catchError(error => {
                  this.authService.logout();
                  this.router.navigateByUrl(RouteUrl.login);
                  return throwError(error);
                }), finalize(() => {
                  this.isRefreshingToken = false;
                  this.checkFinalize();
                }));
              }
              else {
                this.checkFinalize();
                return this.tokenSubject
                  .pipe(
                    filter(token => token != null)
                    , take(1)
                    , switchMap(token => {
                      return next.handle(this.addToken(request, token));
                    })
                  );
              }
            } else {
              this.authService.logout();
              this.router.navigateByUrl(RouteUrl.login);
            }
          } else {
            this.authService.logout();
            this.router.navigateByUrl(RouteUrl.login);
          }
        } else if (error.status === ErrorCode.accessDenied) {
          Swal.fire('Error!', 'You are not authorize to use this functionality.', 'error');
        } else {
          Swal.fire('Error!', 'Something went wrong. Please contact to your administrator', 'error');
        }
        return throwError(error);
      }),
      finalize(() => {
        this.checkFinalize();
      })
    );
  }

  checkFinalize() {
    this.apiCount--;
    if (this.apiCount <= 0) {
      this.apiCount = 0;
      this.loaderService.hide();
    }
  }

  addToken(request: HttpRequest<any>, accessToken: string) {
    let newRequest: HttpRequest<any>;
    if (request && accessToken) {
      newRequest = request.clone({
        headers: request.headers.set('Authorization',
          'Bearer ' + accessToken)
      });
    }
    return newRequest;
  }
}
